// .main {
//     height: calc(100vh - 180px);
//     min-height: calc(100vh - 180px);
// }

body {
    background-color: #f3f5f9;

    .right-10 {
        right: 10px !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .dark-theme {
        display: none;
    }

    .white-theme {
        display: block;
    }

    .msg-item {
        .msg-bubble-answer {
            a {
                color: #424242 !important;

                &:hover {
                    color: #506fd9 !important;
                }
            }
        }
    }

    .main-app.ask-chat-main {
        position: relative;
        min-height: calc(100vh - 72px);
        background-color: #f2f2f2;

        .chat-option-dropdown {
            .dropdown-toggle {
                padding: 2px;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;

                &:focus,
                &:active {
                    border: 0;
                    outline: none;
                }

                &:after {
                    display: none;
                }
            }
        }

        .chat-panel {
            z-index: 2;
            height: calc(100vh - 130px);

            .chat-body-content {
                height: calc(100% - 88px);
            }
        }


    }

    // BTN ICON
    .btn-icon,
    .custom-btn-icon {
        width: 30px;
        height: 30px;

        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }


    // CUSTOM CHAT OPTION DROP DOWN
    .custoom-drop {
        position: relative;
    }

    .custoom-drop .menu-bttn:focus+.dropdown-menu {
        display: block;
        
    }

    .custoom-drop .dropdown-menu {
        position: absolute;
        z-index: 1000;
        display: none;
        min-width: 10rem;
        padding: 0;
        margin: 0;
        font-size: 14px;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 0.25rem;
    }

    .custoom-drop .dropdown-menu a {
        display: block;
        width: 100%;
        padding: 0.25rem 1rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }

    .custoom-drop .dropdown-menu a:hover {
        background-color: #f4f4f4;
    }

    // LOGIN CONTENT
    .login-content-logo {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;

        img {
            filter: brightness(0) invert(1);
        }
    }

    .login-content {
        position: absolute;
        left: 0;
        top: 50%;
        padding: 20px;
        width: 70%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);


        h1 {
            font-size: 3.2vw;
        }

        h2 {
            font-size: 1.2vw;
        }

    }

    // USER PROFILE
    .user-profile {
        color: #424242;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #c3c3c3;
    }

    // OFF CANVAS CSS BEGIN
    .offcanvas-header {
        border-bottom: 0;
        box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);
    }

    .offcanvas-footer {
        border-top: 1px solid rgb(204, 210, 218);
    }

    .offcanvas-footer {
        display: flex;
        align-items: center;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        padding: 1rem 1.5rem;
        justify-content: space-between;
        box-shadow: 0 1px 1px rgba(33, 40, 48, .01), 0px -1px 4px rgba(33, 40, 48, .01), 0 16px 16px rgba(33, 40, 48, .01);
    }

    // COMMON TABS
    .common-tabs {
        background-color: transparent;

        .nav-item {
            .nav-link {
                color: #343434;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                position: relative;
                text-transform: capitalize;
                border-bottom: 1px solid transparent;
                background-color: transparent;

                .badge-length-web {
                    color: #ffffff;
                    margin-left: 5px;
                    background-color: #eb7782;
                }

                i {
                    font-size: 22px;
                }

                &:hover,
                &.active {
                    color: #dc3545;
                    font-weight: 600;
                    border-bottom: 2px solid #dc3545;
                    background-color: transparent;

                    .badge-length-web {
                        color: #ffffff;
                        background-color: #dc3545;
                    }
                }
            }

        }


    }

    table {
        thead,
        tbody {
            tr {
                th,
                td {
                    vertical-align: middle;
                }
            }
        }

        tbody {
            tr {
                td {
                    &.job-title-td {
                        position: relative;
                        padding-left: 15px;
                        &::before {
                            content: "";
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            border: 1px solid ;
                        }

                        &.Live {
                            &::before {
                                background-color: #63e75e;
                                border-color: #5aba4b;
                            }
                        } 

                        &.Ended {
                            &::before {
                                background-color: #dc3545;
                                border-color: #f3392c;
                            }
                        }
                    }
                }
            }
        }
    }

    .update-job-component {
        .tab-content,
        .tab-content > .tab-pane,
        .table-responsive {
            height: 100%;
        }
    }

    // OUTLINE BADGE CLASS
    .outline-badge {
        font-weight: 400;
        font-size: 11px;
        border-radius: 10px;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        display: inline-block;
        padding: 1px 7px;
        border: 1px solid;
        &.badge-outline-success {
            color: #0cb785;
            border-color: #0cb785;
        }
        &.badge-outline-danger {
            color: #dc3545;
            border-color: #dc3545;
        }
        &.badge-outline-primary {
            color: #506fd9;
            border-color: #506fd9;
        }
    }

    // TABLE SHIMMER EFFECT
    .table-shommer-effect {
        table {
            tr {
                td {
                    border: 0;

                    .table-shimmer {
                        background: #f6f7f8;
                        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
                        background-repeat: no-repeat;
                        background-size: 1000px 100%;
                        display: inline-block;
                        position: relative;
                        overflow: hidden;
                        padding: 12px 0;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background: inherit;
                            animation: tableShimmer 1.5s infinite linear;
                        }

                        @keyframes tableShimmer {
                            0% {
                                background-position: -1000px 0;
                            }

                            100% {
                                background-position: 1000px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .table-row-shimmer {
        display: table-row;
    }

    .table-cell-shimmer {
        display: table-cell;
        padding: 10px;
        height: 20px;
        border-bottom: 1px solid #ddd;
    }

}

.account-logo {
    position: absolute;
    left: 85px;
    top: -75px;
    z-index: 2;
}


.nav-sidebar {
    .nav-link {
        &.active {
            background-color: #f8f9fc;
        }
    }
}


.loader {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.main-loader {
    margin-top: 10px;
    text-align: center;
}

.msg-bubble-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .msg-bubble {
        padding: 12px 15px;
        border-radius: 6px;
        line-height: 1.42;
        max-width: 700px;
        background-color: #506fd9;
        color: #fff;
    }

    .nav-icon {
        display: none;

        a {
            font-size: 18px;
            padding: 0;
            color: #6e7985;
            font-size: 18px;
            line-height: 1;
            transition: all .25s;
        }
    }

    &:hover {
        .nav-icon {
            display: flex;
        }
    }
}


@media screen and (max-width: 480px) {
    body {
        .main-app {
            &.ask-chat-main {}
        }
    }
}