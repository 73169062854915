.get-start-loader {
    left: 50%;
    top: 50%;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    h5 {
        margin-top: -4px;
    }
}